.login-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-wrap__container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  color: #454f59;
  background: #fff;
  border: 1px solid #e9ecef;
  box-shadow: 0 4px 10px rgb(0 0 0 / 4%);
  border-radius: 10px;
}

.login-title {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-p {
  font-size: 16px;
}

.login-text ul {
  font-size: 21px;
  display: flex;
  flex-direction: column;
  width: 56%;
}

.login-form {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 0 !important ;
}

.login-input {
  width: 300px !important;
  height: 40px;
  margin: 20px !important;
  padding-top: 5px !important;
  border-radius: 5px !important;
}

.login-submit__button {
  width: 150px !important;
  height: 40px !important;
  font-size: 16px;
  margin: 20px;
  border-radius: 7px !important;
}

@media only screen and (max-width: 960px) {
  .login-wrap__container {
    width: 80%;
  }

  .login-text ul {
    font-size: 17px;
  }

  .login-title{
    height: 65px;
  }
}

@media only screen and (max-width: 567px) {
  .login-wrap__container {
    width: 90%;
  }

  .login-title  h2{
    font-size: 20px;
    margin: 0;
  }

  .login-p {
    font-size: 11px !important;
  }

  .login-text ul {
    font-size: 14px !important;
    width: 80%;
  }
}



@media only screen and (max-width: 1280px) {
  .login-text ul {
    font-size: 17px;
  }

  .login-p {
    font-size: 12px;
  }
}