.tests {
  width: 50%;
  height: calc(100% - 80px) !important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

form {
  margin-top: 10px;
  height: 100%;
}

.test-box {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow: auto;
  height: 100% !important;
}

.test-wrap {
  height: auto !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 20px 10px;
  color: #454f59;
  background: #fff;
  border: 1px solid #e9ecef;
  box-shadow: 0 4px 10px rgb(0 0 0 / 4%);
  border-radius: 10px;
}

.test-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
  padding: 20px;
}

.question {
  padding: 20px;
}

.question h1 {
  margin: 0;
}

.varyants {
  padding: 0 20px;
  height: auto;
}

.check-div {
  display: flex;
  justify-content: space-between;
  height: auto;
  align-items: center;
  margin: 20px auto;
}

.check-div .check-input {
  height: auto;
  margin-right: 10px;
}

.check-div .label {
  width: 95%;
  height: auto;
}

.form-controller {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.form-controller button {
  width: 150px;
  height: 40px;
  margin: 0 40px;
  border-radius: 7px;
}

@media only screen and (max-width: 1280px) {
  .tests {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .tests {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .test-header {
    height: 40px;
  }
  .test-header h2 {
    font-size: 16px;
  }
}
