.timer {
  padding: 10px 20px 15px 20px;
  width: 100%;
  height: 64px !important;
  color: #454f59;
  background: #fff;
  border: 1px solid #e9ecef;
  box-shadow: 0 4px 10px rgb(0 0 0 / 4%);
  border-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.view-time {
  display: flex;
  justify-content: space-between;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  height: 15px;
}

.progress-done {
background: rgb(47,147,238);
background: linear-gradient(90deg, rgba(47,147,238,1) 0%, rgba(27,135,241,1) 0%, rgba(0,212,255,1) 100%);

  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.prosent {
  font-size: 5px;
  height: 20px;
}
