#root {
  width: 100%;
  height: 100%;
  --fg: #17181c;
  --shade1: #727274;
  --shade2: #cccdd1;
  --shade3: #f3f4f8;
  --shade4: #ffffff;
  --dur1: 1s;
  --dur2: 6s;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 99vh;
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 4px;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: rgb(47, 147, 238);
  border-radius: 4px;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}
